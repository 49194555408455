import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';

const GraphicDesignSection = () => {
  const { t } = useTranslation();

  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [isSlideSeen, setIsSlideSeen] = useState(false);
  const disptach = useDispatch();

  useEffect(() => {
    changeColorOnVisible('#ffbf2e', isSlideVisible, disptach);
    if (!isSlideSeen && isSlideVisible) setIsSlideSeen(true);
    // eslint-disable-next-line
  }, [isSlideVisible]);

  const title = t('service.designGraphic.title');

  return (
    <>
      <div className="d-flex overflow-hidden position-relative justify-content-center flex-column min-vh-100">
        <img
          src="/assets/images/graphic/graphic-1.webp"
          alt="art"
          data-aos="fade-left"
          style={{ opacity: 0.8 }}
          data-aos-duration="1000"
          className="position-absolute w-20 top-0 d-none d-md-block right-0"
        />
        <img
          src="/assets/images/graphic/graphic-2.webp"
          data-aos-duration="1000"
          data-aos="fade-right"
          alt="art"
          style={{ opacity: 0.8 }}
          className="position-absolute w-20 bottom-0 left-0 d-none d-md-block"
        />
        <div className="container text-justify space-bottom-md-4 space-bottom-3 position-relative">
          <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
            {() => (
              <div className="d-flex align-items-center justify-content-center mb-md-11  mt-11">
                <span className="display-1  text-white text-center position-relative p-0" data-letters={title}>
                  {title}
                </span>
              </div>
            )}
          </ReactVisibilitySensor>
          <img
            src="/assets/images/graphic/graphic-mobil.webp"
            alt="art"
            data-aos="fade-up"
            className="w-100 d-block d-md-none"
            data-aos-duration="1000"
          />
          <div className="letter-effect font-size-3 text-white font-weight-normal ">{t('service.designGraphic.1')}</div>
          <div className="letter-effect font-size-3 text-white font-weight-normal ">{t('service.designGraphic.2')}</div>
          <div className="letter-effect font-size-3 text-white font-weight-normal ">{t('service.designGraphic.3')}</div>
          <div className="letter-effect font-size-3 text-white font-weight-normal ">{t('service.designGraphic.4')}</div>
          <Link to="/graphic-design" className="align-self-end continue-reading text-white">
            {t('seeMore')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default GraphicDesignSection;
